<template>
  <div class="app-sidebar">
    <img class="cornerleft" src="../../../assets/image/cornerleft.png" />
    <logo :elMenuCollapse="elMenuCollapse" />
    <div>
      <search></search>
      <el-menu
        ref="meun"
        class="el-menu-vertical-demo"
        :default-active="defaultActive"
        background-color="transparent"
        text-color="#ffffff"
      >
        <menu-tree v-for="item in sidebarList" :key="item.path" :item="item"></menu-tree>
      </el-menu>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Logo from './logo'
import Search from './search'
import MenuTree from './menu-tree'

export default {
  components: { MenuTree, Logo, Search },
  props: {
    isPhone: {
      type: Boolean,
      default: false,
    },
    isCollapse: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    elMenuCollapse() {
      // if (this.isPhone) {
      //   return false
      // }
      // return this.isCollapse
    },
    /**
     * 根据当前路由设置激活侧边栏
     */
    defaultActive() {
      const route = this.$route
      return route.path
    },
    ...mapGetters(['sidebarList']),
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
.cornerleft {
  position: absolute;
  left: 210px;
  top: 0px;
  height: 45px;
  width: 45px;
  z-index: 3;
}
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::v-deep.el-menu {
  background-color: transparent;

  .el-menu-item {
    color: #ffffff;
    height: 40px;
    padding: 0px !important;
    margin: 8px;
    border-radius: 4px;
    line-height: 40px;
    div {
      padding: 0px !important;
    }
    i {
      color: #ffffff;
    }
  }

  .el-menu-item.is-active {
    color: #577df4 !important;
    background: #ffffff !important;
    div {
      padding: 0px !important;
    }
    .iconfont {
      padding: 11px 14px;
      font-size: 18px;
      background: #ffffff;
      position: relative;
      left: -4px;
      border-radius: 5px;
      box-shadow: 4px 0px 10px 0px rgba(0, 36, 125, 0.43);
    }
  }
  .el-submenu__title i {
    color: #ffffff;
  }

  .el-submenu {
    .el-menu-item {
      min-width: 180px;
    }

    .el-menu--inline {
      // background-color: rgb(76, 112, 226) !important;
      padding-left: 8px !important;
      .el-menu-item {
        // background-color: rgb(76, 112, 226) !important;
        padding-left: 16px;
      }
      .is-active {
        background-color: #ffffff !important;
      }
    }
    .el-submenu__title {
      // background: rgb(76, 112, 226) !important;
      padding-left: 8px !important;
      color: #ffffff !important;
      .iconfont {
        color: #ffffff !important;
      }
      &:hover {
        background-color: rgb(61, 90, 181) !important;
      }
    }
  }
}

::v-deep .el-menu--collapse .el-submenu.is-active .el-submenu__title {
  background: transparent !important;
  padding-left: 18px !important;
  div {
    padding: 0px !important;
  }
  .iconfont {
    padding: 11px 14px;
    font-size: 18px;
    background: #ffffff;
    color: #577df4 !important;
    position: relative;
    left: -15px;
    border-radius: 5px;
    // box-shadow: 4px 0px 10px 0px rgba(0, 36, 125, 0.43);
  }
}

::v-deep .el-menu--collapse {
  .el-submenu {
    .el-submenu__title {
      padding-left: 16px !important;
    }
  }
}

.app-sidebar {
  // background: #192a5e;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}
</style>
